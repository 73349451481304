import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {App} from './app';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './tools/shared.module';
import {AppRoutes} from './app.routes';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {NZ_I18N, zh_CN} from 'ng-zorro-antd';
import {UEditorModule} from 'ngx-ueditor';
import zh from '@angular/common/locales/zh';

registerLocaleData(zh);

@NgModule({
  declarations: [
    App
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutes,
    UEditorModule.forRoot({
      js: [
        `./assets/ueditor/ueditor.all.min.js`,
        `./assets/ueditor/ueditor.config.js`,
      ],
      options: {
        UEDITOR_HOME_URL: './assets/ueditor/'
      }
    })
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: NZ_I18N, useValue: zh_CN }
  ],
  bootstrap: [App]
})
export class AppModule { }
