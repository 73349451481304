import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {HttpService} from './http.service';
import {UtilsService} from './utils.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ImagePipe } from '../directive/image/image.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { DisabledPipe } from './pipes/disabled.pipe';
import { DefaultInterceptor } from './cache/net/default.interceptor';
const PIPES = [StatusPipe, DisabledPipe];
/**
 * 公共module，其它module只需导入shared.module
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgZorroAntdModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    ImagePipe,
    ...PIPES,
  ],
  declarations: [
    ImagePipe,
    ...PIPES,
  ],
  providers: [
    { provide: 'utilsService', useClass: UtilsService },
    { provide: 'httpService', useClass: HttpService },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor,multi:true},
  ]
})
export class SharedModule { }
