import { Injectable } from "@angular/core";
import {
  NzMessageService,
  NzModalService,
  NzTreeNode,
  NzNotificationService
} from "ng-zorro-antd";
import { isNumber } from "util";
declare let BMap: any;

/**
 * UtilsService工具类存放和业务无关的公共方法
 * @description
 */
@Injectable()
export class UtilsService {
  fmtYMD: string = "yyyy-MM-dd"; //时间格式 YMD
  fmtYMDHMS: string = "yyyy-MM-dd hh:mm:ss"; //时间格式 YMDHMS
  fmtYMDHM: string = "yyyy-MM-dd hh:mm"; //时间格式 YMDHM
  loading: boolean = false; //加载层
  constructor(
    private modalService: NzModalService,
    private messageService: NzMessageService,
    private notificationService: NzNotificationService
  ) {}

  /**
   * 判断是否为null、空
   * @param value
   * @returns {boolean}
   */
  isEmpty(value) {
    return (
      value == null ||
      (typeof value === "string" &&
        (value.length === 0 || value == undefined || value == "undefined"))
    );
  }

  /**
   * 判断是否非null、空
   * @param value
   * @returns {boolean}
   */
  isNotEmpty(value) {
    return !this.isEmpty(value);
  }

  /**
   * 判断是否JSON对象
   * @param value
   */
  isJson(value): boolean {
    return (
      typeof value == "object" &&
      Object.prototype.toString.call(value).toLowerCase() ==
        "[object object]" &&
      !value.length
    );
  }

  /**
   * 获取缓存对象
   * @param key
   * @param value
   */
  getSessionStorage(key) {
    return sessionStorage.getItem(key);
  }

  /**
   * 设置缓存对象
   * @param key
   * @param value
   */
  setSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
  }

  /**
   * 清除缓存对象
   * @param key
   */
  clearSessionStorage(key: string = null) {
    if (key != null) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.clear();
    }
  }

  /**
   * 格式化日期
   * @param date 日期
   * @param fmt 格式
   * @returns {string}
   */
  format(date: Date, fmt: string = ""): string {
    fmt = fmt || this.fmtYMDHMS;
    let o = {
      "M+": date.getMonth() + 1, //月份
      "d+": date.getDate(), //日
      "h+": date.getHours(), //小时
      "m+": date.getMinutes(), //分
      "s+": date.getSeconds(), //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return fmt;
  }

  /**
   * 获取当前日期时、分、秒
   * @returns {string}
   */
  getDate(paramJson: Object = {}): string {
    let fmt = paramJson["fmt"] || this.fmtYMDHMS;
    if (this.isEmpty(paramJson["timestamp"])) {
      return this.format(new Date(), fmt);
    } else {
      return this.format(new Date(paramJson["timestamp"]), fmt);
    }
  }

  /**
   * 获取当前活动路由的URL
   * @returns {string}
   */
  getActivatedRouteUrl(activatedRoute, url: string = "") {
    url = url || activatedRoute.snapshot["_routerState"]["url"];
    let splitIndex = url.indexOf("?"); //url 中的?分割符索引
    if (splitIndex > -1) {
      url = url.substring(0, splitIndex);
    }
    return url;
  }

  /**
   * 根据KEY获取权限信息
   * @param key
   * @returns {any}
   */
  getAuthStorageByKey(key) {
    return this.getAuthStorage()[key];
  }

  /**
   * 获取权限信息
   * @returns {any}
   */
  getAuthStorage() {
    return JSON.parse(this.getSessionStorage("authStorage") || "{}");
  }
  /**
   * 提示消息窗口
   * @param param
   */
  message(param: Object = {}) {
    let content: string = ""; //提示文本
    let type: string = param["type"] || "success"; //提示类型： info、success(默认)、error、warning
    if (param["success"] == false) {
      type = "error";
    }

    switch (type) {
      case "error":
        content = param["message"] || "网络忙,请稍候再尝试...";
        break;
      case "info":
        content = param["message"] || "提示!";
        break;
      case "warning":
        content = param["message"] || "警告!";
        break;
      default:
        content = param["message"] || "操作成功!";
    }

    this.messageService.create(type, content);
  }

  /**
   * 设置左侧菜单相关信息缓存--以路由路径为KEY
   */
  setRouterStorage(key, value) {
    let routerStorage: Object = JSON.parse(
      sessionStorage.getItem("routerStorage") || "{}"
    );
    routerStorage[key] = value;
    sessionStorage.setItem("routerStorage", JSON.stringify(routerStorage));
  }

  /**
   * 获取路由缓存信息
   * @returns {any}
   */
  getRouterStorage() {
    return JSON.parse(this.getSessionStorage("routerStorage") || "{}");
  }

  /**
   * 加载loading层
   */
  showLoading() {
    this.loading = true;
  }

  /**
   * 关闭loading层
   */
  closeLoading() {
    this.loading = false;
  }

  /**
   * 弹框
   * @param param.component 弹框面板
   * @param param.modalParam 弹框参数 默认为{}
   * @param callback 点击提交或确认后回调
   */
  openModal(param: Object = {}, callback?: Function) {
    this.modalService.create({
        nzContent: param["component"],
        nzFooter: null,
        nzZIndex: param["zIndex"] || 1000,
        nzWidth: param["width"] || 750,
        nzStyle: param["style"] || { top: "80px" },
        nzMaskClosable: false,
        nzComponentParams: {
          modalParam: param["modalParam"] || {}
        }
      }).afterClose.subscribe(result => {
        if (callback != null) {
          callback(result);
        }
      });
  }

  /**
   * 拍照、选择照片选择base64数组
   * @param arrBase64 base64数组
   * @param event 待操作的数组或索引
   */
  getArrBase64(arrBase64, base64OrIndex) {
    if (isNumber(base64OrIndex)) {
      //整形为索引时删除图片
      arrBase64 = [
        ...arrBase64.slice(0, base64OrIndex),
        ...arrBase64.slice(base64OrIndex + 1)
      ];
    } else {
      arrBase64 = [...arrBase64, base64OrIndex];
    }
    return arrBase64;
  }

  /**
   * 请求结果处理，返回data
   * @param result 结果
   * @param successBack 回调
   * @param paramJson参数配置
   * @param failBack 回调
   */
  resultToData(
    result,
    successBack?: Function,
    paramJson: Object = {},
    failBack?: Function
  ) {
    paramJson = Object.assign({ showSuccessMsg: false }, paramJson);
    // 关闭loading层
    this.closeLoading();
    if (this.isJson(result)) {
      console.log("result", result);
      if (result["errcode"] == 0) {
        if (paramJson["showSuccessMsg"]) {
          this.message({
            message: result.retdata["message"] || result.Retdata["message"]
          });
        }

        if (successBack != null) {
          successBack(result["retdata"] || result["Retdata"]);
        }
      } else {
        this.message({
          // message: result.retdata["message"] || result.Retdata["message"],
          message:result.Retdata["message"],
          success: false
        });
        if (failBack != null) {
          failBack(result["retdata"] || result["Retdata"]);
        }
      }
    } else {
      this.message({
        message: result.Retdata["message"] || "",
        success: false
      });
      if (failBack != null) {
        failBack(result["Retdata"]);
      }
    }
  }




  resultToDatas(
    result,
    successBack?: Function,
    paramJson: Object = {},
    failBack?: Function
  ) {
    paramJson = Object.assign({ showSuccessMsg: false }, paramJson);
    // 关闭loading层
    this.closeLoading();
    if (this.isJson(result)) {
      console.log("result", result);
      if (result["errcode"] == 0) {
        if (paramJson["showSuccessMsg"]) {
          this.message({
            message: result.Retdata["message"]
          });
        }

        if (successBack != null) {
          successBack( result["Retdata"]);
        }
      } else {
        this.message({
          // message: result.retdata["message"] || result.Retdata["message"],
          message:result.Retdata["message"],
          success: false
        });
        if (failBack != null) {
          failBack( result["Retdata"]);
        }
      }
    } else {
      this.message({
        message: result.Retdata["message"] || "",
        success: false
      });
      if (failBack != null) {
        failBack(result["Retdata"]);
      }
    }
  }

  /**
   * 请求结果处理，返回data
   * @param result 结果
   * @param successBack 回调
   * @param paramJson参数配置
   * @param failBack 回调
   */
  resultData(
    result,
    successBack?: Function,
    paramJson: Object = {},
    failBack?: Function
  ) {
    paramJson = Object.assign({ showSuccessMsg: false }, paramJson);
    // 关闭loading层
    this.closeLoading();
    //判断是否为JSON格式
    if (this.isJson(result)) {
      console.log("result", result);
      if (result["errcode"] == 0) {
        if (paramJson["showSuccessMsg"]) {
          if (result["Retdata"]["result"] === 1) {
            this.message({ message: "操作成功", success: true });
            // this.message({ message: result.retdata["message"]||result.Retdata["message"] });
          }

          // this.message({ message: result.retdata["message"]||result.Retdata["message"] });
        }

        if (successBack != null) {
          successBack(result["retdata"] || result["Retdata"]);
        }
      } else {
        this.message({
          message: result.retdata["message"] || result.Retdata["message"],
          success: false
        });
        if (failBack != null) {
          failBack(result["retdata"] || result["Retdata"]);
        }
      }

      // if (result["errcode"] == 0) {
      //   if (paramJson["showSuccessMsg"]) {
      //     this.message({ message: result.retdata["message"]||result.Retdata["message"] });
      //   }

      //   if (successBack != null) {
      //     successBack(result["retdata"] || result["Retdata"]);
      //   }
      // } else {
      //   this.message({ message: result.retdata["message"]||result.Retdata["message"], success: false });
      //   if (failBack != null) {
      //     failBack(result["retdata"] || result["Retdata"]);
      //   }
      // }
    } else {
      this.message({
        message: result.Retdata["message"] || "",
        success: false
      });
      if (failBack != null) {
        failBack(result["retdata"]);
      }
    }
  }

  /**
   * 请求结果处理，返回data
   * @param result 结果
   * @param successBack 回调
   * @param paramJson参数配置
   * @param failBack 回调
   */
  resultToDataByLogin(
    result,
    successBack?: Function,
    paramJson: Object = {},
    failBack?: Function
  ) {
    paramJson = Object.assign({ showSuccessMsg: false }, paramJson);
    this.closeLoading();
    if (this.isJson(result)) {
      if (result["errcode"] == 0) {
        if (paramJson["showSuccessMsg"]) {
          this.message({ message: result["errmsg"] });
        }

        if (successBack != null) {
          successBack(result["retdata"] || result["Retdata"]);
        }
      } else {
        this.message({ message: result["errmsg"], success: false });
        if (failBack != null) {
          failBack(result["retdata"] || result["Retdata"]);
        }
      }
    } else {
      this.message({ message: result["errmsg"] || "", success: false });
      if (failBack != null) {
        failBack(result["retdata"]);
      }
    }
  }

  /**
   * 确认对话框
   * @param param
   */
  confirm(onOk: Function, param: Object = {}, onCancel?: Function) {
    let arrMsg: string[] = [
      "您是否要删除该记录吗?",
      "您是否要提交该记录吗?",
      "您是否要删除所选中的记录吗?",
      "您是否要批量提交所操作的记录吗"
    ];
    let title: string = param["message"] || ""; //对话框提示信息
    if (title == "") {
      let msgIndex: number = param["msgIndex"] || 0; //索引默认为0
      if (msgIndex < arrMsg.length) {
        title = arrMsg[msgIndex]; //对话框提示信息
      }
    }

    this.modalService.confirm({
      nzTitle: title,
      nzZIndex: param["zIndex"] || 1001,
      nzOnOk: () => {
        onOk();
      },
      nzOnCancel: () => {
        if (onCancel != null) {
          onCancel();
        }
      }
    });
  }

  /**
   * 下拉框选择后返回文本值
   * @param value
   * @param data
   */
  getSelectKey(
    value,
    data,
    callback: Function,
    param: Object = { key: "valueName", value: "value" }
  ) {
    let tempData: Object[] = data.filter(item => {
      return item[param["value"]] == value;
    });
    callback(tempData.length == 0 ? "" : tempData[0][param["key"]]);
  }

  /**
   * 下拉框选择后返回文本值
   * @param value
   * @param data
   */
  getMultiSelectKey(
    value,
    data,
    callback: Function,
    param: Object = { key: "DICT_NAME", value: "DICT_VALUE" }
  ) {
    let result: Object = {};
    result[param["key"]] = "";
    result[param["value"]] = "";
    if (value.length > 0) {
      let tempValue: Object[] = [];
      let tempKey: Object[] = [];
      value.forEach(item => {
        data.forEach(im => {
          if (item == im[param["value"]]) {
            tempValue = [...tempValue, item];
            tempKey = [...tempKey, im[param["key"]]];
          }
        });
      });
      result[param["key"]] = tempKey;
      result[param["value"]] = tempValue;
    }
    callback(result, param);
  }

  /**
   * 字符串转换成日期
   * @param date
   * @returns {Date}
   */
  parserDate(date: string): Date {
    if (this.isEmpty(date)) {
      return new Date();
    } else {
      let t = Date.parse(date.replace(/-/g, "/"));
      if (!isNaN(t)) {
        return new Date(t);
      } else {
        return new Date();
      }
    }
  }

  /**
   * 字符串转换成日期
   * @param date
   * @returns {Date}
   */
  parserDateBy(date: string) {
    var d = new Date(date);
    var datetime =
      d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    return datetime;
  }

  /**
   * 获取用户缓存
   * @param key
   */
  getLoginUserBySessionStorage() {
    return JSON.parse(this.getSessionStorage("loginUser") || "{}");
  }

  /**
   * 该方法用于将有父子关系的数组转换成树形结构的数组
   * 接收一个具有父子关系的数组作为参数
   * 返回一个树形结构的数组
   * @param data
   * @param params
   */
  translateDataToTree(data, params: Object = {}) {
    params = Object.assign(
      {
        id: "id",
        parentId: "parentId",
        children: "children",
        key: "key",
        title: "title"
      },
      params
    );
    let parents = data.filter(item => this.isEmpty(item[params["parentId"]]));
    let children = data.filter(item =>
      this.isNotEmpty(item[params["parentId"]])
    );
    let translator = (parents, children) => {
      parents.forEach(parent => {
        if (parent.hasOwnProperty(params["key"])) {
          parent["key"] = parent[params["key"]];
        }

        if (parent.hasOwnProperty(params["title"])) {
          parent["title"] = parent[params["title"]];
        }
        children.forEach((current, index) => {
          if (current[params["parentId"]] === parent[params["id"]]) {
            let temp = JSON.parse(JSON.stringify(children));
            temp.splice(index, 1);
            translator([current], temp);
            typeof parent[params["children"]] !== "undefined"
              ? parent[params["children"]].push(current)
              : (parent[params["children"]] = [current]);
          }
        });

        if (
          parent.hasOwnProperty(params["children"]) &&
          parent[params["children"]] != null &&
          parent[params["children"]].length > 0
        ) {
          parent["isLeaf"] = false;
        } else {
          parent["isLeaf"] = true;
        }
      });
    };

    translator(parents, children);
    return parents;
  }

  /**
   * 根据文件后缀名判断文件是否为图片
   * @param fileName
   */
  isAssetTypeAnImage(fileName) {
    let index = fileName.lastIndexOf(".");
    let ext = fileName.substr(index + 1);
    return (
      [
        "png",
        "jpg",
        "jpeg",
        "bmp",
        "gif",
        "webp",
        "psd",
        "svg",
        "tiff"
      ].indexOf(ext.toLowerCase()) !== -1
    );
  }

  /**
   * 根据地址获取经纬度
   * @param params
   * @param successBack 成功回调
   * @param failBack 失败回调
   */
  getPoint(params: Object = {}, successBack?: Function, failBack?: Function) {
    let myGeo = new BMap.Geocoder();
    myGeo.getPoint(params["address"], p => {
      if (p != null) {
        if (successBack != null) {
          successBack(p);
        }
      } else {
        if (failBack != null) {
          failBack();
        }
        this.message({
          message: params["message"] || "请输入完整的地址以免定位失败...",
          success: false
        });
      }
    });
  }

  /**
   * 生成guid
   */
  guid() {
    return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, c => {
      let r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  /**
   * 同步树数据转换成nzTreeNode需要的树数据格式
   * @param result 树数据
   * @param option 配置
   */
  convToNzTreeNode(result, option: Object = {}) {
    let treeData: Object[] = [];
    this.convTreeNode(result, option).forEach(item => {
      treeData = [
        ...treeData,
        new NzTreeNode(
          Object.assign(
            {
              title: "",
              key: ""
            },
            item
          )
        )
      ];
    });
    return treeData;
  }

  /**
   * 返回的结果为同步树数据时转换为树节点需要的数据格式
   * @param result 同步树数据
   * @param option 配置
   */
  convTreeNode(result, option: Object = {}) {
    option = Object.assign(
      {
        title: "text",
        key: "recNo",
        checked: "checked",
        children: "children",
        isLeaf: "leaf",
        
      },
      option
    );
    let tempData: Object[] = [];
    for (let i = 0; i < result.length; i++) {
      let treeNode: Object = {};
      treeNode["title"] = result[i][option["title"]];
      treeNode["key"] = result[i][option["key"]];
      // treeNode["isLeaf"] = result[i][option["isLeaf"]];
      treeNode["isLeaf"] =
        result[i][option["children"]] != null &&
        result[i][option["children"]].length > 0
          ? false
          : true;
      treeNode["checked"] =
        result[i][option["checked"]] == true ||
        result[i][option["checked"]] == "true"
          ? true
          : false;
      if (!treeNode["isLeaf"]) {
        treeNode["checked"] = false;
      }

      tempData = [...tempData, treeNode];
      if (!result[i][option["isLeaf"]]) {
        let temp = result[i][option["children"]];
        if (temp) {
          tempData[i]["children"] = this.convTreeNode(temp, option);
        }
      }
    }
    console.log(tempData);
    return tempData;
  }

  /**
   * 获取checkbox选中的节点
   * @param treeData
   * @param nodeKey
   * @returns {string[]}
   */
  getTreeCheckNode(
    treeData,
    nodeKey: string = "key",
    halfChecked: boolean = false
  ) {
    let tempData: Object[] = [];
    for (let i = 0; i < treeData.length; i++) {
      // console.log("treeData",treeData);
      if (
        treeData[i]["isChecked"] ||
        (halfChecked && treeData[i]["isHalfChecked"])
      ) {
        if (nodeKey == "") {
          tempData = [...tempData, treeData[i]];
        } else {
          tempData = [...tempData, treeData[i][nodeKey]];
        }
      }

      if (!treeData[i]["isLeaf"]) {
        let temp = treeData[i]["children"];
        if (temp) {
          tempData = [
            ...tempData,
            ...this.getTreeCheckNode(temp, nodeKey, halfChecked)
          ];
        }
      }
    }
    return tempData;
  }

  /**
   * 获取influxDb转图表数据
   * @param result
   */
  getEChartsDataByInfluxDb2(result, callback?: Function) {
    let echartsData: Object[] = [];

    let results: Object[] = JSON.parse(result["data"] || "{}")["results"];
    // console.log("results",results);
    if (results != null && results.length > 0) {
      let series: Object[] = results[0]["series"];
      // console.log("series",series);
      if (series != null && series.length > 0) {
        let values: Object[] = series[0]["values"];
        // console.log("values",values);
        values.forEach(item => {
          // console.log("item",item);
          echartsData = [...echartsData, { value: item }];
          // console.log("echartsData",echartsData);
        });
      }
    }
    if (callback != null) {
      callback(echartsData);
    }
  }


    /**
 * 同步树数据转换成nzTreeNode需要的树数据格式
 * @param result 树数据
 * @param option 配置
 */
 convToNzTreeNodeByText(result, option: Object = {}) {
  let treeData: Object[] = [];
  this.convTreeNodeByText(result, option).forEach(item => {
    treeData = [...treeData, new NzTreeNode(Object.assign({
      title: '',
      key: ''
    }, item))];
  });
  return treeData;
 }

 
/**
 * 返回的结果为同步树数据时转换为树节点需要的数据格式
 * @param result 同步树数据
 * @param option 配置
 */
convTreeNodeByText(result, option: Object = {}) {

  option = Object.assign({
    title: 'text',
    key: 'recNo',
    checked: 'checked',
    children: 'children',
    isLeaf: 'leaf'
  }, option);

  let tempData: Object[] = [];

  for (let i = 0; i < result.length; i++) {
    let treeNode: Object = {};
    treeNode['title'] = result[i][option['title']];
    treeNode['key'] = result[i][option['key']];
    treeNode['checked'] = result[i][option['checked']] == true || result[i][option['checked']] == 'true' ? true : false;
    treeNode['isLeaf'] = result[i][option['children']]!=null&&result[i][option['children']].length>0?false:true;
    if(!treeNode['isLeaf']){
      treeNode['checked'] = false;
    }
    tempData = [...tempData, treeNode];
    if (!result[i][option['isLeaf']]) {
      let temp = result[i][option['children']];
      if (temp) {
        tempData[i]['children'] = this.convTreeNodeByText(temp, option);
      }
    }
  }
  return tempData;

}

}
